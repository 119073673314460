<script setup>
import { HaToaster, useNotifications } from '@ha/components-v3'
const { haToaster } = useNotifications()

useHead({
  titleTemplate: '%s | HelloAsso, la solution de paiement des associations'
})
</script>

<template>
  <div class="layout-wrapper">
    <HaToaster ref="haToaster" />
    <div class="layout-wrapper__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.cookie = 'ha_gdpr_has_given_consent=; Max-Age=0'
  }
}
</script>

<style lang="scss">
:root {
  /* Mint to Cobalt */
  --ha-color-primary: hsl(245, 60%, 53%);
  --ha-color-primary-h: 245;
  --ha-color-primary-s: 60%;
  --ha-color-primary-l: 53%;

  /* Dream to Mint */
  --ha-color-secondary: hsl(148, 61%, 56%);
  --ha-color-secondary-h: 148;
  --ha-color-secondary-s: 61%;
  --ha-color-secondary-l: 56%;

  /* Dream */
  --ha-color-tertiary: hsl(220, 88%, 80%);
  --ha-color-tertiary-h: 220;
  --ha-color-tertiary-s: 88%;
  --ha-color-tertiary-l: 80%;
}

.layout-wrapper {
  background: #f0effd;

  &__content {
    max-width: $ha-unit * 100; // 800px
    min-height: 100vh;
    margin: 0 auto;
    padding: $ha-spacing-big $ha-spacing-medium;
    background: var(--ha-color-white);
  }
}
</style>
